import React from "react"
import { graphql } from 'gatsby'
import DashboardIFrame from "Sections/DashboardIFrame/DashboardIFrame.jsx"
import SEO from "Src/components/seo";

const Country = props => {
  const { pageContext, data } = props

  return (
    <>
      <SEO title={pageContext.title} />
      <DashboardIFrame iFrame country={data.country.edges[0] && data.country.edges[0].node || data.altCountry.edges[0].node} countries={data.relatedCountries.edges} pageContext={pageContext} />
    </>
  )
}


export default Country

export const query = graphql`
  query iFrameCountryQuery($id: String!) {
    country: allCountriesJson(
      filter: {id: {eq: $id}}
    ) {
      edges {
        node {
          id
          slug
          title
          flag
          flagPng {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          slitScanHorizontal
          dataset {
            primary {
              area {
                start
                mid
                end
              }
              temperature {
                start
                mid
                end
              }
            }
            secondary {
              retreat
              population
              emissions
              landmass
            }
            diagram {
              hasUNESCOGlaciers
              data {
                end
                label
                lat
                long
                start
              }
            }
            copy
          }
        }
      }
    }
    altCountry: allCountriesAlternativesJson(
      filter: {id: {eq: $id}}
    ) {
      edges {
        node {
          id
          slug
          title
          flag
          flagPng {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          slitScanHorizontal
          dataset {
            primary {
              area {
                start
                mid
                end
              }
              temperature {
                start
                mid
                end
              }
            }
            secondary {
              retreat
              population
              emissions
              landmass
            }
            diagram {
              hasUNESCOGlaciers
              data {
                end
                label
                lat
                long
                start
              }
            }
            copy
          }
        }
      }
    }
    relatedCountries: allCountriesJson {
      edges {
        node {
          id
          slug
          title
          flagPng {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          slitScanHorizontal
        }
      }
    }
  }
`
